/* eslint-disable react/prop-types */
import 'intersection-observer';
import * as React from 'react';
import styled from 'styled-components';
import MainLayout from 'components/Layout/MainLayout';
import { withHomePageSection } from 'components/withHomePageSection';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import { fromScreen } from 'utils/media-query/responsive.util';
import { LevelContext } from '../shared/Marketing/Online/DolRegister/components/withProgramByLevel';
import { TeacherDetailHeader } from 'shared/DolTeacherDetail/TeacherDetailHeader';
import { TeacherHighlight } from 'shared/DolTeacherDetail/TeacherHighlight';
import { TeacherStyle } from 'shared/DolTeacherDetail/TeacherStyle';
import { ClassOfTeacher } from 'shared/DolTeacherDetail/ClassOfTeacher';
import StudentImage from 'shared/DolTeacherDetail/StudentImage';
import { useAllTeacherData } from 'shared/DolTeachers/AllDolTeachers';
import { StudentCourseReviews } from 'shared/StudentCourseLanding/StudentReviews';
import get from 'lodash/get';
import { FixedTopBarScholarshipScheduleNOffline } from 'shared/SchedulePage/FixedTopBarScholarship/FixedTopBarScholarshipScheduleNOffline';
import { graphql, useStaticQuery } from 'gatsby';
import MySEO from 'shared/MySEO';

export const useReviewFacebook = teacherName => {
  const data = useStaticQuery(graphql`
    {
      allGoogleSpreadsheetDolLandingPageReviewAll(
        filter: { listing: { eq: "listed" } }
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            id
            linkReview
            linkAvatar
            linkCover
            fullName
            date
            reviewContent
            listing
            classType
            program
            teacherSl
            teacherRw
            order
          }
        }
      }
    }
  `);

  const reviews = data.allGoogleSpreadsheetDolLandingPageReviewAll.edges.filter(
    item => {
      const isValidClassType =
        item.node.teacherRw?.includes(teacherName) ||
        item.node.teacherSl?.includes(teacherName);
      const isNotBlank =
        (get(item, 'node.reviewContent', '') || '').trim().length > 0;
      return isValidClassType && item.node.linkAvatar && isNotBlank;
    }
  );

  return { reviews };
};

const Space = styled.div`
  > div {
    padding: 32px 0;
    ${fromScreen(776)} {
      padding: 80px 0;
      &:first-child {
        padding: 100px 0 80px 0;
      }
      &:last-child {
        padding: 80px 0 100px 0;
      }
    }
  }
`;

const ContentSection = withHomePageSection(
  ({ teacher, courseOfTeacher, reviews }) => {
    const { teacherDescription, videoIntroduction, teacherMomentImageList } =
      teacher;

    return (
      <Space>
        <TeacherStyle
          teacherDescription={teacherDescription}
          videoIntroduction={videoIntroduction}
        />
        <StudentImage allImage={teacherMomentImageList} />
        {/* <StudentCourseReviews
          data={reviews}
          program="IELTS"
          title="Review của học viên"
          columns={{ default: 3, 1143: 2, 775: 1 }}
        /> */}

        {courseOfTeacher.length > 0 && (
          <ClassOfTeacher listCourse={courseOfTeacher} teacherInfo={teacher} />
        )}
      </Space>
    );
  }
);

const TeacherDetailTemplate = React.memo(props => {
  const { pageContext } = props;
  const { teacherInfo } = pageContext;
  const {
    name,
    fullName,
    bannerImageHandler,
    displayNameSubtitle,
    quote,
    highlights,
    slug
  } = teacherInfo;

  const { getCourseOfTeacher } = useAllTeacherData();
  const courseOfTeacher = getCourseOfTeacher(name);
  const { reviews } = useReviewFacebook(name);
  const seoName = slug.includes('Thay') ? `Thầy ${fullName}` : `Cô ${fullName}`;

  return (
    <LevelContext.Provider>
      <MainLayout
        noHeader
        hideSideCTA
        renderCustomHeader={() => <HeaderSchedulePage />}
      >
        <MySEO
          slug={slug}
          h1={seoName}
          hidePostfix
          title={`${seoName} - Giáo viên tại DOL ENGLISH`}
          description={`Cùng nghe ${seoName} - Linearthinking Ambassador tại DOL English chia sẻ về triết lý giáo dục của mình qua đó hiểu thêm về phương pháp Linearthinking`}
        />
        <FixedTopBarScholarshipScheduleNOffline />
        <TeacherDetailHeader
          teacherName={fullName}
          subTitle={displayNameSubtitle}
          bannerImage={bannerImageHandler}
          quote={quote}
        />
        <TeacherHighlight highlights={highlights} />
        <ContentSection
          teacher={teacherInfo}
          courseOfTeacher={courseOfTeacher}
          reviews={reviews}
        />
      </MainLayout>
    </LevelContext.Provider>
  );
});

TeacherDetailTemplate.propTypes = {};

export default TeacherDetailTemplate;
